{
   "pages" : {
      "about" : "About PomBase",
      "about/citing-pombase" : "Citing PomBase",
      "about/collaborations" : "PomBase collaborations",
      "about/cookie-policy" : "PomBase Cookie Policy",
      "about/data-sources" : "PomBase Data Sources",
      "about/index" : "About PomBase",
      "about/new-pombase" : "New, improved PomBase",
      "about/pombase-and-collaborative-publications" : "PomBase and collaborative publications",
      "about/pombase-sab" : "PomBase Scientific Advisory Board",
      "about/pombase-staff" : "Contact us",
      "about/privacy-policy" : "PomBase Privacy Policy",
      "about/projects" : "PomBase ongoing projects",
      "about/resource-metrics" : "Resource Metrics",
      "about/terms-of-use" : "PomBase Terms of Use",
      "about/version-history" : "PomBase data version history",
      "browse-curation" : "Browse curation",
      "browse-curation/disease-slim" : "Monarch Disease Ontology slim",
      "browse-curation/dna-binding-sites" : "DNA binding site consensus sequences in S. pombe",
      "browse-curation/drugs-known-pombe-targets" : "Drugs with known S. pombe targets",
      "browse-curation/fission-yeast-bp-go-slim-terms" : "Fission yeast GO Biological Process slim",
      "browse-curation/fission-yeast-cc-go-slim-terms" : "Fission yeast GO Cellular Component slim",
      "browse-curation/fission-yeast-go-slimming-tips" : "PomBase GO Slim Usage Tips",
      "browse-curation/fission-yeast-mf-go-slim-terms" : "Fission yeast GO Molecular Function slim",
      "browse-curation/fission-yeast-phenotype-ontology" : "Fission Yeast Phenotype Ontology",
      "browse-curation/fypo-slim" : "Fission Yeast Phenotype Ontology slim",
      "browse-curation/index" : "Browse curation",
      "community" : "Fission Yeast Community",
      "community/2006-survey-q16" : "2006 GeneDB S. pombe survey, question 16 responses",
      "community/2006-survey-q17" : "2006 GeneDB S. pombe survey, question 17 responses",
      "community/2006-survey-q18" : "2006 GeneDB S. pombe survey, question 18 responses",
      "community/2006-survey-q19" : "2006 GeneDB S. pombe survey, question 19 responses",
      "community/fission-yeast-community-curation-pilot-project" : "Participating groups & Annotated papers",
      "community/fission-yeast-community-curation-project" : "The Fission Yeast Community Curation Project",
      "community/index" : "Fission Yeast Community",
      "community/internet-resources" : "S. pombe Community Websites",
      "community/pombase_2006_survey_summary" : "2006 survey for GeneDB S. pombe",
      "datasets" : "Datasets",
      "datasets/index" : "Datasets",
      "documentation" : "PomBase Web Site Help & Documentation",
      "documentation/Broad_dataset_email" : "Email: Import of the fission yeast revisions from the Broad Institute comparative genome paper into GeneDB",
      "documentation/JBrowse_quick_start" : "Getting started with PomBase JBrowse",
      "documentation/Lanterman_Dutrow_dataset_email" : "Email: Annotated transcription start and termination sites for fission yeast.",
      "documentation/advanced-search" : "Advanced search",
      "documentation/annotation-extension-relation-display" : "Annotation extension relation display",
      "documentation/controlled-curation" : "PomBase controlled curation",
      "documentation/data-submission-form-for-HTP-sequence-linked-data" : "HTP sequence-linked data submission instructions",
      "documentation/disease-association" : "Gene page: Disease associations",
      "documentation/gene-page-basic-information" : "Gene page: Basic information",
      "documentation/gene-page-external-references" : "Gene page: External references",
      "documentation/gene-page-gene-expression" : "Gene page: Gene expression",
      "documentation/gene-page-gene-ontology" : "Gene page: Gene Ontology",
      "documentation/gene-page-gene-structure-history" : "Gene page: Gene structure history",
      "documentation/gene-page-literature" : "Gene page: Literature",
      "documentation/gene-page-modifications" : "Gene page: Modifications",
      "documentation/gene-page-phenotypes" : "Gene page: Phenotypes",
      "documentation/gene-page-protein-features" : "Gene page: Protein domains and properties",
      "documentation/gene-page-protein-features-widget" : "Gene page: Protein feature widget",
      "documentation/gene-page-sequence" : "Gene page: Sequence",
      "documentation/gene-page-target" : "Gene page: Target of",
      "documentation/gene-page-transcript" : "Gene page: Transcript",
      "documentation/genetic-and-physical-interactions" : "Genetic and physical interactions",
      "documentation/genotype-page" : "Genotype pages",
      "documentation/getting-started" : "Getting started",
      "documentation/go-cam-pathway-models" : "GO-CAM pathways",
      "documentation/high-confidence-physical-interaction-network" : "High Confidence Physical Interaction Network (HCPIN)",
      "documentation/id-mapper" : "Identifier mapper",
      "documentation/index" : "PomBase Web Site Help & Documentation",
      "documentation/misc-sequence-features" : "Miscellaneous sequence features",
      "documentation/modification-data-bulk-upload-format" : "Modification data bulk upload format",
      "documentation/motif-search" : "Peptide motif search",
      "documentation/ontology-term-page" : "Ontology term pages",
      "documentation/orthologs" : "Ortholog curation",
      "documentation/phenotype-data-bulk-upload-format" : "Phenotype data bulk upload format",
      "documentation/pombase-go-slim-documentation" : "GO Slims - Gene Ontology subsets",
      "documentation/publication-page" : "Publication pages",
      "documentation/qualitative-gene-expression-data-bulk-upload-format" : "Qualitative gene expression bulk upload file format",
      "documentation/quantitative-gene-expression-data-bulk-upload-format" : "Quantitative gene expression bulk upload file format",
      "documentation/quick-little-tool" : "Quick Little Tool",
      "documentation/simple-search-documentation" : "Simple search",
      "documentation/taxonomic-conservation" : "Taxonomic conservation",
      "downloads" : "Downloads",
      "downloads/chado-database-dumps" : "Chado database dumps",
      "downloads/genome-datasets" : "Genome sequence and features",
      "downloads/go-annotations" : "GO annotations",
      "downloads/index" : "Downloads",
      "downloads/intron-data" : "Introns",
      "downloads/modifications" : "Protein modifications",
      "downloads/names-and-identifiers" : "PomBase name and identifier mappings",
      "downloads/phenotype-annotations" : "Phenotype annotations",
      "downloads/protein-datasets" : "Protein datasets",
      "faq" : "Frequently asked questions",
      "faq/can-i-access-pombase-via-api" : "Can I access PomBase via an API?",
      "faq/can-i-access-pombase-via-sql" : "Can I access PomBase via SQL?",
      "faq/can-i-convert-file-gff-gtf" : "Can I convert a file from GFF to GTF?",
      "faq/can-i-convert-file-gtf-gff3" : "Can I convert a file from GTF to GFF3?",
      "faq/can-i-convert-ids-other-databases-or-pombase-ids" : "Can I convert IDs from other databases to or from PomBase IDs?",
      "faq/can-i-do-enrichment-analysis-using-phenotypes" : "Can I do an enrichment analysis using phenotypes?",
      "faq/can-i-download-all-s.-pombe-phenotype-data" : "Can I download all *S. pombe* phenotype data?",
      "faq/can-i-download-interaction-data-used-by-esyn" : "Can I download the interaction data used by esyN?",
      "faq/can-i-download-sequence-whole-chromosome" : "Can I download the sequence for a whole chromosome?",
      "faq/can-i-download-sequences-many-genes-once-including-flanking-regions" : "Can I download sequences for many genes at once, including flanking regions?",
      "faq/can-i-export-data-sequence-region-genome-browser" : "Can I export data for a sequence region from the genome browser?",
      "faq/can-i-find-all-unconserved-orphan-genes-fission-yeast" : "Can I find all of the unconserved (orphan) genes in fission yeast?",
      "faq/can-i-find-promoters-pombase" : "Can I find promoters in PomBase?",
      "faq/can-i-find-replication-origins-pombase" : "Can I find replication origins in PomBase?",
      "faq/can-i-find-unmapped-genes-pombase" : "Can I find unmapped genes in PomBase?",
      "faq/can-i-generate-comprehensive-restriction-enzyme-map-genome-pombase" : "Can I generate a comprehensive restriction enzyme map of the genome in PomBase?",
      "faq/can-i-get-cdna-sequences-s.-pombe" : "Can I get cDNA sequences for *S. pombe*?",
      "faq/can-i-get-file-specific-set-genome-features" : "Can I get a file with a specific set of genome features?",
      "faq/can-i-get-list-essential-pombe-genes" : "Can I get a list of essential pombe genes?",
      "faq/can-i-get-list-systematic-ids-primary-names-synonyms-and-gene-products-s.-pombe" : "Can I get a list of systematic IDs, primary names, synonyms and gene products in *S. pombe*?",
      "faq/can-i-obtain-dump-all-curated-data-pombase" : "Can I obtain a dump of all curated data in PomBase?",
      "faq/can-i-provide-list-genes-search-on" : "Can I provide a list of genes to search on?",
      "faq/can-i-retrieve-annotations-metabolic-databases-such-as-ec-metacyc-reactome-or-rhea-s.-pombe-genes" : "Can I retrieve annotations to metabolic databases such as EC, MetaCyc, Reactome, or Rhea for *S. pombe* genes?",
      "faq/can-i-retrieve-functional-annotations-genes-list" : "Can I retrieve functional annotations for genes in a list?",
      "faq/can-i-retrieve-list-s.-pombe-go-slim-terms" : "Can I retrieve a list of the S. pombe GO slim terms?",
      "faq/can-i-retrieve-sequence-any-cosmids-used-build-s.-pombe-genome-sequence" : "Can I retrieve the sequence for any of the cosmids used to build the *S. pombe* genome sequence?",
      "faq/can-i-search-gene-list-and-retrieve-results-same-order-as-input-list" : "Can I search for a gene list and retrieve results in the same order as in the input list?",
      "faq/can-i-search-genes-based-on-conservation-different-taxa" : "Can I search for genes based on conservation in different taxa?",
      "faq/can-i-search-pombase-protein-features" : "Can I search PomBase for protein features?",
      "faq/can-i-share-my-search-results" : "Can I share my search results?",
      "faq/can-i-use-blast-find-s.-pombe-sequences-similar-my-query-sequence" : "Can I use BLAST to find *S. pombe* sequences similar to my query sequence?",
      "faq/can-i-use-blast-search-short-sequences" : "Can I use BLAST to search for short sequences?",
      "faq/can-i-use-wild-card-beginning-search-string" : "Can I use a wild card at the beginning of a search string?",
      "faq/can-i-view-my-data-privately-genome-browser" : "Can I view my data privately in the genome browser?",
      "faq/can-i-view-my-protein-interest-multiple-alignment-related-proteins-other-species" : "Can I view my protein of interest in a multiple alignment with related proteins in other species?",
      "faq/can-i-view-nucleotide-sequence-genome-browser" : "Can I view nucleotide sequence in the genome browser?",
      "faq/can-i-view-variation-data-pombase" : "Can I view variation data in PomBase?",
      "faq/can-i-visualise-networks-pombase" : "Can I visualise networks in PomBase?",
      "faq/do-i-need-password-download-ftp-site" : "Do I need a password to download from the FTP site?",
      "faq/do-you-have-polyadenylation-data-fission-yeast" : "Do you have polyadenylation data for fission yeast?",
      "faq/help-canto-isn-t-working-me.-do-you-have-any-suggestions" : "Help! Canto isn't working for me. Do you have any suggestions?",
      "faq/how-are-mating-type-specific-gene-pages-organized" : "How are the mating type specific gene pages organized?",
      "faq/how-are-non-coding-rna-genes-identified" : "How are non-coding RNA genes identified?",
      "faq/how-are-pombase-systematic-ids-determined" : "How are PomBase systematic IDs determined?",
      "faq/how-are-sequence-feature-types-defined-pombase" : "How are sequence feature types defined in PomBase?",
      "faq/how-can-i-browse-phenotype-ontology-fypo" : "How can I browse the phenotype ontology (FYPO)?",
      "faq/how-can-i-display-sequence-region-using-sequence-coordinates-genome-browser" : "How can I display a sequence region using sequence coordinates in the genome browser?",
      "faq/how-can-i-find-all-genes-have-given-mutant-phenotype" : "How can I find all of the genes that have a given mutant phenotype?",
      "faq/how-can-i-find-all-s.-pombe-genes-are-conserved-human" : "How can I find all *S. pombe* genes that are conserved in human?",
      "faq/how-can-i-find-all-s.-pombe-proteins-particular-protein-family-or-have-particular-domain" : "How can I find all *S. pombe* proteins in a particular protein family, or that have a particular domain?",
      "faq/how-can-i-find-all-sequence-features-region-using-chromosome-coordinates" : "How can I find all sequence features in a region using chromosome coordinates?",
      "faq/how-can-i-find-genes-region-using-chromosome-coordinates" : "How can I find genes in a region using chromosome coordinates?",
      "faq/how-can-i-find-genes-specific-activity" : "How can I find genes with a specific activity?",
      "faq/how-can-i-find-intron-branch-sites" : "How can I find intron branch sites?",
      "faq/how-can-i-find-modifications-my-protein-interest" : "How can I find modifications for my protein of interest?",
      "faq/how-can-i-find-mutants-increased-or-decreased-sensitivity-chemicals" : "How can I find mutants with increased or decreased sensitivity to chemicals",
      "faq/how-can-i-find-orthologs-between-s.-pombe-and-other-schizosaccharomyces-species" : "How can I find orthologs between *S. pombe* and other *Schizosaccharomyces* species?",
      "faq/how-can-i-find-protein-localization-data" : "How can I find protein localization data?",
      "faq/how-can-i-find-proteins-have-transmembrane-domains" : "How can I find proteins that have transmembrane domains?",
      "faq/how-can-i-find-rrna-genes" : "How can I find rRNA genes?",
      "faq/how-can-i-find-s.-pombe-genes-associated-human-disease" : "How can I find *S. pombe* genes associated with human disease?",
      "faq/how-can-i-find-s.-pombe-genes-complement-or-are-complemented-by-gene-another-species" : "How can I find *S. pombe* genes that complement or are complemented by a gene from another species?",
      "faq/how-can-i-find-s.-pombe-ortholog-s-human-gene" : "How can I find *S. pombe* ortholog(s) of a human gene?",
      "faq/how-can-i-find-s.-pombe-orthologs-species-other-than-human-and-s.-cerevisiae" : "How can I find *S. pombe* orthologs for species other than human and *S. cerevisiae*?",
      "faq/how-can-i-find-significant-shared-fypo-annotations-genes-list" : "How can I find significant shared FYPO annotations for genes in a list?",
      "faq/how-can-i-find-significant-shared-go-annotations-genes-list" : "How can I find significant shared GO annotations for genes in a list?",
      "faq/how-can-i-find-snorna-genes" : "How can I find snoRNA genes?",
      "faq/how-can-i-find-transcription-factor-binding-sites-pombase" : "How can I find transcription factor binding sites in PomBase?",
      "faq/how-can-i-find-transcription-factors-and-their-targets-pombase" : "How can I find transcription factors and their targets in PomBase?",
      "faq/how-can-i-find-transposons-genome" : "How can I find transposons in the genome?",
      "faq/how-can-i-get-cosmids-my-favorite-gene" : "How can I get cosmids for my favorite gene?",
      "faq/how-can-i-get-my-data-into-pombase" : "How can I get my data into PomBase?",
      "faq/how-can-i-identify-all-genes-affect-process" : "How can I identify all of the genes that affect a process?",
      "faq/how-can-i-join-pombeslack" : "How can I join pombeSlack?",
      "faq/how-can-i-locate-centromeres" : "How can I locate centromeres?",
      "faq/how-can-i-locate-mating-type-region" : "How can I locate the mating type region?",
      "faq/how-can-i-locate-telomeres-and-subtelomeric-regions" : "How can I locate telomeres and subtelomeric regions?",
      "faq/how-can-i-obtain-list-human-and-s.-pombe-orthologs" : "How can I obtain the list of human and *S. pombe* orthologs?",
      "faq/how-can-i-retrieve-all-s.-pombe-genes" : "How can I retrieve all *S. pombe* genes?",
      "faq/how-can-i-retrieve-all-s.-pombe-protein-coding-genes" : "How can I retrieve all *S. pombe* protein-coding genes?",
      "faq/how-can-i-retrieve-gene-sequence-including-upstream-and-downstream-sequences" : "How can I retrieve a gene sequence, including upstream and downstream sequences?",
      "faq/how-can-i-retrieve-intron-coordinates-or-sequences" : "How can I retrieve intron coordinates or sequences?",
      "faq/how-can-i-retrieve-sequence-coordinates-all-features-particular-type" : "How can I retrieve sequence coordinates for all features of a particular type?",
      "faq/how-can-i-retrieve-sequence-region-using-sequence-coordinates" : "How can I retrieve the sequence of a region using sequence coordinates?",
      "faq/how-can-i-retrieve-sequences-fasta-file-custom-headers" : "How can I retrieve sequences in a FASTA file with custom headers?",
      "faq/how-can-i-retrieve-sequences-non-protein-coding-genes" : "How can I retrieve sequences for non-protein-coding genes?",
      "faq/how-can-i-retrieve-utr-sequences" : "How can I retrieve UTR sequences?",
      "faq/how-can-i-search-genes-involved-both-go-process-and-regulation-process" : "How can I search for genes involved in both a GO process and regulation of the process?",
      "faq/how-can-i-search-or-browse-go-annotations" : "How can I search or browse GO annotations?",
      "faq/how-can-i-search-s.-cerevisiae-ortholog-s-homolog-s-s.-pombe-gene" : "How can I search for the *S. cerevisiae* ortholog(s)/homolog(s) of an *S. pombe* gene?",
      "faq/how-can-i-see-nucleotide-level-similarity-between-s.-pombe-and-other-schizosaccharomyces-species" : "How can I see nucleotide-level similarity between *S. pombe* and other *Schizosaccharomyces* species?",
      "faq/how-can-i-show-or-hide-tracks-genome-browser" : "How can I show or hide tracks in the genome browser?",
      "faq/how-can-i-submit-high-throughput-data-pombase" : "How can I submit high-throughput data to PomBase?",
      "faq/how-can-i-use-go-slims-s.-pombe" : "How can I use GO slims with *S. pombe*?",
      "faq/how-do-i-change-track-scale-jbrowse" : "How do I change the track scale in JBrowse?",
      "faq/how-do-i-cite-data-pombase-how-do-i-cite-genome-sequence-comparison-data-etc." : "How do I cite data from PomBase? How do I cite the genome sequence, comparison data, etc.?",
      "faq/how-do-i-download-table-all-modifications" : "How do I download a table of all modifications?",
      "faq/how-do-i-join-pombelist-can-you-unsubscribe-me-pombelist" : "How do I join pombelist? Can you unsubscribe me from pombelist?",
      "faq/how-do-i-receive-updates-list-s.-cerevisiae-and-s.-pombe-orthologs" : "How do I receive updates to the list of *S. cerevisiae* and *S. pombe* orthologs?",
      "faq/how-do-i-reserve-gene-name" : "How do I reserve a gene name?",
      "faq/how-does-pombase-determine-gene-s-full-length-transcript-utr-coordinates-transcription-start-and-end-sites" : "How does PomBase determine a gene’s full-length transcript? / UTR coordinates? / transcription start and end sites?",
      "faq/how-many-conserved-unstudied-proteins-are-there-how-list-generated" : "How many conserved unstudied proteins are there? How is the list generated?",
      "faq/i-found-discrepancy-between-genbank-embl-ena-or-ddbj-entry-and-sequence-pombase.-what-should-i-do" : "I found a discrepancy between a GenBank (EMBL/ENA or DDBJ) entry and a sequence in PomBase. What should I do?",
      "faq/index" : "Frequently asked questions",
      "faq/s.-pombe-transcriptome-available-fasta-format" : "Is the *S. pombe* transcriptome available in FASTA format?",
      "faq/there-any-programmatic-access-pombase-data" : "Is there any programmatic access to PomBase data?",
      "faq/there-any-rdna-repeat-sequences-pombase" : "Are there any rDNA repeat sequences in PomBase?",
      "faq/there-equivalent-artemis-java-applet-pombase" : "Is there an equivalent to the Artemis java applet in PomBase?",
      "faq/there-list-drug-targets-s.-pombe" : "Is there a list of drug targets in *S. pombe*?",
      "faq/there-list-protein-complexes-s.-pombe-and-their-subunits" : "Is there a list of protein complexes in *S. pombe*, and their subunits?",
      "faq/what-annotation-extension" : "What is an annotation extension?",
      "faq/what-bam-format" : "What is BAM format?",
      "faq/what-bed-format" : "What is BED format?",
      "faq/what-bedgraph-format" : "What is bedGraph format?",
      "faq/what-bigbed-format" : "What is bigBed format?",
      "faq/what-bigwig-format" : "What is bigWig format?",
      "faq/what-does-characterisation-status-mean-gene" : "What does the \"characterisation status\" mean for a gene?",
      "faq/what-file-formats-can-i-use-submit-high-throughput-data" : "What file formats can I use to submit high-throughput data?",
      "faq/what-gff3" : "What is GFF3?",
      "faq/what-go-term-enrichment-how-can-i-do-it-my-genes" : "What is GO term enrichment? How can I do it for my genes?",
      "faq/what-pombemine-service-url" : "What is the PombeMine service URL",
      "faq/what-psl-format" : "What is PSL format?",
      "faq/what-vcf" : "What is VCF?",
      "faq/what-wig-format" : "What is WIG format?",
      "faq/when-was-genomic-sequence-last-updated" : "When was the genomic sequence last updated?",
      "faq/where-can-i-download-genome-sequence" : "Where can I download the genome sequence?",
      "faq/where-can-i-find-basic-statistics-on-s.-pombe-genome-such-as-genome-size-total-number-genes-mean-intergenic-distance-etc." : "Where can I find basic statistics on the *S. pombe* genome, such as genome size, total number of genes, mean intergenic distance, etc.?",
      "faq/where-can-i-find-information-about-pombase-data-versions" : "Where can I find information about PomBase data versions?",
      "faq/why-are-go-annotations-different-between-pombase-and-uniprot-goa" : "Why are GO annotations different between PomBase and UniProt/GOA?",
      "faq/why-are-go-terms-missing-downloadable-annotation-file" : "Why are GO terms missing from the downloadable annotation file?",
      "faq/why-are-pombelist-messages-delayed-why-don-t-i-get-pombelist-emails-promptly" : "Why are pombelist messages delayed? Why don't I get pombelist emails promptly?",
      "faq/why-are-some-genes-abnormal-phenotype-annotated-corresponding-go-process-while-others-are-not" : "Why are some genes with an abnormal phenotype annotated to the corresponding GO process while others are not?",
      "faq/why-are-some-genes-annotated-both-viable-and-inviable-phenotypes" : "Why are some genes annotated to both viable and inviable phenotypes?",
      "faq/why-do-gene-pages-only-show-curated-orthologs-human-and-s.-cerevisiae" : "Why do gene pages only show curated orthologs for human and *S. cerevisiae*?",
      "help" : "Help",
      "help/index" : "Help",
      "news" : "News archive",
      "news/2002-12-31-global-transcriptional-responses-fission-yeast-environmental-stress" : "Global transcriptional responses of fission yeast to environmental stress",
      "news/2003-02-28-schizosaccharomyces-pombe-essential-genes-pilot-study" : "Schizosaccharomyces pombe Essential Genes: A pilot Study",
      "news/2003-08-31-new-fission-yeast-book-now-published" : "The 'new' fission yeast book is now published",
      "news/2003-10-31-recent-genome-wide-surveys" : "Recent Genome wide surveys",
      "news/2004-04-30-methods-volume-33-issue-3" : "Methods Volume 33 Issue 3",
      "news/2004-08-29-third-international-fission-yeast-meeting" : "The Third International Fission Yeast Meeting",
      "news/2004-08-31-general-repository-interaction-datasets" : "General Repository for Interaction Datasets",
      "news/2005-11-12-second-east-coast-regional-pombe-meeting" : "Second East Coast Regional pombe Meeting",
      "news/2005-11-16-comparative-genomics-eukaryotic-microorganisms" : "Comparative Genomics of Eukaryotic Microorganisms",
      "news/2006-03-17-european-fission-yeast-meeting" : "European Fission Yeast Meeting",
      "news/2006-04-30-fission-yeast-database-survey" : "Fission yeast database survey",
      "news/2006-06-30-first-fission-yeast-whole-proteome-localization-study-now-published" : "The first fission yeast whole proteome localization study is now published",
      "news/2006-09-30-yeast-special-issue-2006-european-fission-yeast-meeting" : "Yeast Special Issue from the 2006 European Fission Yeast Meeting",
      "news/2006-12-31-genedb-representation-fission-yeast-data-moved-contigs-chromosomes" : "GeneDB representation of the fission yeast data moved from contigs to chromosomes",
      "news/2007-05-31-4th-international-fission-yeast-meeting" : "4th International Fission Yeast Meeting",
      "news/2007-09-30-wellcome-trust-advanced-course-genome-wide-approaches-fission-yeast" : "Wellcome Trust Advanced Course 'Genome-wide approaches with fission yeast",
      "news/2007-12-31-baumann-and-zakian-labs-identify-telomerase-rna" : "Baumann and Zakian labs identify telomerase RNA",
      "news/2008-01-31-h-mating-type-region-has-been-provided" : "The h- mating type region has been provided",
      "news/2008-04-30-global-sequence-and-chip-study-examines-eukaryotic-transcription" : "Global sequence and chip study examines eukaryotic transcription",
      "news/2008-11-30-s-pombe-genedb-now-includes-deep-links-biological-general-repository-interaction-datasets-biogr" : "S. pombe GeneDB now includes \"deep links\" to the Biological General Repository for Interaction Datasets (BioGRID)",
      "news/2009-08-31-genedb-now-using-version-23-pfam-protein-family-database" : "GeneDB is now using Version 23 of the Pfam protein family database.",
      "news/2009-09-30-fission-yeast-ensembl-fungi" : "Fission yeast in Ensembl Fungi",
      "news/2009-10-31-genedb-s-pombe-now-uses-latest-update-pfam-release-240" : "GeneDB (S. pombe) now uses the latest update to Pfam, release 24.0",
      "news/2009-11-30-fission-yeast-one-12-key-organisms-reference-genomes-project" : "Fission yeast is one of the 12 key organisms of the reference genomes project",
      "news/2010-02-28-funding-pombase" : "Funding for PomBase",
      "news/2010-05-15-analysis-fission-yeast-deletion-publication" : "Analysis of Fission Yeast Deletion Publication",
      "news/2011-01-31-annotated-transcription-start-and-termination-sites-fission-yeast" : "Annotated transcription start and termination sites for fission yeast",
      "news/2011-02-01-genome-reappraisal-reveals-new-genes-and-revised-gene-structures" : "Genome reappraisal reveals new genes and revised gene structures",
      "news/2011-04-21-schizosaccharomyces-comparative-genome-paper-published" : "Schizosaccharomyces Comparative Genome Paper Published",
      "news/2011-04-28-genedb-s-pombe-now-uses-latest-release-pfam-protein-family-database250" : "GeneDB (S. pombe) now uses the latest release of the Pfam protein family database(25.0).",
      "news/2011-10-27-pombase-nar-paper-published-online" : "PomBase NAR paper published online",
      "news/2011-11-27-pombase-preview-launch" : "PomBase preview launch",
      "news/2012-07-01-pombase-web-site-fully-live" : "PomBase web site fully live",
      "news/2012-11-06-new-data-and-new-features-pombase-web-site" : "New data and new features on PomBase web site",
      "news/2013-03-01-speed-improvements-and-new-data-pombase-web-site" : "Speed improvements and new data on PomBase web site",
      "news/2013-04-01-data-update-pombase-web-site" : "Data update on PomBase web site",
      "news/2013-04-02-pombe-2013-registration-abstracts-mon-8th-april" : "Pombe 2013: registration & abstracts by Mon 8th April",
      "news/2013-04-11-carl-singer-foundation-established" : "Carl Singer Foundation Established",
      "news/2013-05-07-quantitative-gene-expression-data-available-pombase" : "Quantitative gene expression data available in PomBase",
      "news/2013-05-13-genedb-s-pombe-decommissioned" : "GeneDB S. pombe decommissioned",
      "news/2013-05-20-pombase-data-update" : "PomBase data update",
      "news/2013-06-20-pombase-data-update-0" : "PomBase data update 2013-06-20",
      "news/2013-06-23-pombase-launches-community-curation" : "PomBase launches community curation",
      "news/2013-07-18-pombase-website-update" : "PomBase website update",
      "news/2013-07-21-pombelist-has-moved" : "pombelist has moved",
      "news/2013-07-29-connecting-pombase" : "Connecting With PomBase",
      "news/2013-08-18-send-htp-data-pombase" : "Send HTP data to PomBase",
      "news/2013-09-15-pombase-data-update-1" : "PomBase data update 2013-09-15",
      "news/2013-09-18-pombase-user-survey-open" : "PomBase User Survey open",
      "news/2013-10-21-pombase-data-update-2" : "PomBase data update 2013-10-21",
      "news/2013-10-27-new-target-gene-page-section" : "New \"Target Of\" gene page section",
      "news/2013-11-20-pombase-survey-results-available" : "PomBase survey results available",
      "news/2013-11-24-2013-meeting-mini-reviews-published" : "2013 meeting mini-reviews published",
      "news/2013-12-08-pombase-data-update-3" : "PomBase data update 2013-12-08",
      "news/2014-02-19-human-ortholog-data-correction-coming-next-month" : "Human ortholog data correction coming next month",
      "news/2014-02-20-pombase-data-update-4" : "PomBase data update 2014-02-20",
      "news/2014-03-20-pombase-data-update-5" : "PomBase data update 2014-03-20",
      "news/2014-05-15-pombase-data-update-6" : "PomBase data update 2014-05-15",
      "news/2014-06-29-gene-ontology-publication-annotation-extensions" : "Gene Ontology publication on annotation extensions",
      "news/2014-07-08-pombase-data-update-7" : "PomBase data update 2014-07-08",
      "news/2014-07-17-pombase-data-update-8" : "PomBase data update 2014-07-17",
      "news/2014-08-18-pombase-data-update-9" : "PomBase data update 2014-08-18",
      "news/2014-09-16-pombase-data-update-10" : "PomBase data update 2014-09-16",
      "news/2014-11-12-pombase-data-update-11" : "PomBase data update 2014-11-12",
      "news/2014-12-10-esyn-network-visualizations-pombase" : "esyN network visualizations in PomBase",
      "news/2014-12-10-new-compact-go-annotation-display" : "New compact GO annotation display",
      "news/2015-01-26-pombase-data-update-12" : "PomBase data update 2015-01-26",
      "news/2015-02-16-pombase-data-update-13" : "PomBase data update 2015-02-16",
      "news/2015-02-26-pombe-2015-registration-now-open" : "Pombe 2015 registration now open",
      "news/2015-03-23-pombase-data-update-14" : "PomBase data update 2015-03-23",
      "news/2015-04-09-pombe-2015-abstract-deadline-approaching" : "Pombe 2015 abstract deadline approaching",
      "news/2015-04-19-pombase-data-update-15" : "PomBase data update 2015-04-19",
      "news/2015-04-19-pombe-2015-poster-abstract-deadline-extended" : "Pombe 2015 poster abstract deadline extended",
      "news/2015-04-23-pombe-2015-travel-fellowships" : "Pombe 2015 travel fellowships",
      "news/2015-05-26-canto-downtime-new-version" : "Canto downtime & new version",
      "news/2015-05-26-pombase-data-update-16" : "PomBase data update 2015-05-26",
      "news/2015-06-16-pombase-data-update-17" : "PomBase data update 2015-06-16",
      "news/2015-09-03-pombase-data-update-multi-allele-phenotypes" : "PomBase data update with multi-allele phenotypes",
      "news/2015-09-28-pombase-data-update-viability-summary-correction-alert" : "PomBase data update; viability summary correction alert",
      "news/2015-10-19-new-genetics-primer-fission-yeast" : "New genetics primer for fission yeast",
      "news/2015-12-02-new-advanced-search-features" : "New Advanced Search features",
      "news/2015-12-02-pombase-data-update-18" : "PomBase data update 2015-12-02",
      "news/2016-02-11-pombase-data-update-19" : "PomBase data update 2016-02-11",
      "news/2016-04-11-pombase-data-update-20" : "PomBase data update 2016-04-11",
      "news/2016-05-09-pombase-data-update-21" : "PomBase data update 2016-05-09",
      "news/2016-05-31-pombase-data-update-22" : "PomBase data update 2016-05-31",
      "news/2016-06-15-our-model-organism-database-commentary" : "Our model organism database commentary",
      "news/2016-06-27-show-your-support-database-funding" : "Show your support for database funding",
      "news/2016-10-19-pombase-data-update-23" : "PomBase data update 2016-10-19",
      "news/2016-10-31-9th-international-fission-yeast-meeting-registration-open" : "9th International Fission Yeast Meeting registration open",
      "news/2016-12-11-9th-international-fission-yeast-meeting-early-registration-closes-soon" : "9th International Fission Yeast Meeting - early registration closes soon",
      "news/2017-10-24-new-improved-pombase-goes-live" : "New, improved PomBase goes live",
      "news/2017-11-24-congratulations-to-gsa-award-winners" : "Congratulations to GSA award winners",
      "news/2018-04-05-andre-goffeau" : "In memory of Andr&eacute; Goffeau",
      "news/2018-04-16-pombase-deploys-jbrowse" : "PomBase releases JBrowse",
      "news/2018-05-22-book-chapter" : "New book chapter about PomBase",
      "news/2018-05-28-new-browse-tracks" : "New genome browser tracks",
      "news/2018-08-31-mailing-list-move-to-cam" : "pombelist changes",
      "news/2018-10-06-transcript-tracks-from-Atkinson-et-al-(2018)-loaded" : "Transcript tracks from Atkinson et al. (2018) loaded",
      "news/2018-10-10-2019-fungal-pathogen-genomics-course-registration-open" : "Fungal Pathogen Genomics Course 2019 - registration open",
      "news/2018-10-15-nar-database-issue" : "PomBase NAR Database Issue",
      "news/2018-10-23-jbrowse-graphics-gene-page" : "New PomBase genomic region graphics",
      "news/2018-11-06-rna-central" : "RNA central and PomBase",
      "news/2018-11-08-20-years-GO" : "Celebrating 20 years of GO",
      "news/2018-11-14-S.-pombe-in-your-pocket" : "PomBase in your pocket",
      "news/2018-11-17-conserved-unknowns-pub" : "Hidden in plain sight: What remains to be discovered in the eukaryotic proteome?",
      "news/2018-11-20-tm-transport-overhaul" : "Fission yeast transmembrane transport overhaul",
      "news/2018-11-21-quick-little-tool" : "See your genes in a QuiLT",
      "news/2018-12-03-new-nucleosome-occupancy-browser-maps-loaded" : "New nucleosome occupancy maps loaded",
      "news/2018-12-17-mitochondrial-protein-review" : "Mitochondrial GO annotation update",
      "news/2019-01-09-improved-disease-association-dataset-released" : "Improved disease association dataset released",
      "news/2019-02-12-wood-isb-award" : "Val Wood wins Biocuration society award",
      "news/2019-02-15-pombe-2019-registration-open" : "Pombe 2019 - registration open",
      "news/2019-02-20-conserved-unknowns-open-biol" : "Published: Hidden in plain sight: What remains to be discovered in the eukaryotic proteome?",
      "news/2019-02-27-trieste-cell-cycle-meeting" : "International Cell Cycle meeting - registration open",
      "news/2019-03-04-serym2019" : "South Eastern Regional Yeast Meeting (SERYM) - registration open",
      "news/2019-03-05-list-go-slim-tool" : "GO slim for any S. pombe gene list",
      "news/2019-03-13-icygmb2019" : "ICYGMB 2019  - registration open",
      "news/2019-03-19-motif-search-integrated" : "Integrated motif search",
      "news/2019-04-16-pombe-gene-info-extension" : "S. pombe included in Gene Info browser extension",
      "news/2019-04-17-pombase-interpro-update" : "PomBase InterPro Update",
      "news/2019-04-18-customisable-fasta" : "Customisable FASTA download",
      "news/2019-04-18-prediction-links" : "New homology modelling and ortholog links on gene pages",
      "news/2019-05-19-tRNA_metabolism_update" : "tRNA metabolism GO annotation update",
      "news/2019-07-11-Fitness_Landscape_of_the_Fission_Yeast_Genome-data-loaded" : "\"Fitness Landscape of the Fission Yeast Genome\" dataset loaded into JBrowse",
      "news/2019-07-19-join-the-conversations-on-slack" : "Join the conversations on Slack",
      "news/2019-08-30-replication-origin-tracks-loaded" : "Replication origin data loaded into JBrowse",
      "news/2019-10-06-interpro-v76-loaded" : "PomBase now uses InterPro Version 76.0",
      "news/2019-10-07-new-wt-pombase-funding" : "New PomBase funding from the Wellcome Trust",
      "news/2019-10-16-JBrowse-tracks-loadable-from-publication-pages" : "Browser tracks now loadable from publication pages",
      "news/2019-11-28-shareable-result-links" : "Unique permanent URLs for search results",
      "news/2020-01-07-new-stable-integration-vectors" : "New vectors for simple, reliable S. pombe molecular biology",
      "news/2020-01-17-pombase-elixir-node-service" : "PomBase is now an ELIXIR Node Service",
      "news/2020-02-04-lmo14-strasbourg" : "Levures, Modèles et Outils 14th International Conference",
      "news/2020-02-05-advanced-search-phenotype-conditions" : "Query phenotypes for conditions",
      "news/2020-02-20-quarter_million" : "A quarter of a million annotations",
      "news/2020-02-28-50_percent" : "Community curation response rate reaches 50%",
      "news/2020-03-01-interpro-v77" : "PomBase now uses InterPro Version 77.0",
      "news/2020-03-23-angeli" : "AnGeLi update",
      "news/2020-03-23-new-search-result-download-options" : "New search result download options",
      "news/2020-04-22-pombetalks" : "PombeTalks start on April 29th",
      "news/2020-04-28-midori-biocuration-career-award" : "Midori Harris receives 2020 Biocuration Career Award",
      "news/2020-05-01-mitochondrial-genome-update" : "Mitochondrial genome update",
      "news/2020-05-05-interpro-v79" : "PomBase now uses InterPro Version 79.0",
      "news/2020-05-07-chromatin-silencing" : "Chromatin silencing ontology &amp; annotation overhaul",
      "news/2020-05-07-pombetalks-may-13" : "PombeTalks May 13th",
      "news/2020-05-11-community-curation-publication" : "Published: Community curation in PomBase",
      "news/2020-05-18-increased-disease-annotation-coverage" : "Improved gene-disease curation",
      "news/2020-05-18-pombase-uses-mondo" : "PomBase adopts MONDO for disease gene curation",
      "news/2020-05-20-advanced-search-slim-options" : "New ontology slimming options for advanced search results",
      "news/2020-05-20-new-fission-yeast-go-slims" : "New fission yeast GO slims",
      "news/2020-05-21-pombetalks-may-27" : "PombeTalks May 27th",
      "news/2020-06-05-pombetalks-june-10" : "PombeTalks POSTPONED to June 17th",
      "news/2020-06-11-name-queries" : "Editable PomBase query names",
      "news/2020-06-19-pombetalks-june-24" : "PombeTalks June 24th",
      "news/2020-07-02-pombetalks-july-8" : "PombeTalks July 8th",
      "news/2020-07-21-pombetalks-july-22" : "PombeTalks July 22nd",
      "news/2020-07-22-fission-yeast-micropublications" : "Fission yeast microPublications",
      "news/2020-07-31-pombetalks-aug-5" : "PombeTalks August 5th",
      "news/2020-08-12-pombetalks-aug-19" : "PombeTalks August 19th",
      "news/2020-08-19-11th-pombe-conference-postponed" : "11th Pombe meeting postponed to 2022",
      "news/2020-09-01-pombetalks-sept-2" : "PombeTalks September 2nd",
      "news/2020-09-02-GO-term-matrix-publication" : "Published: GO Term Matrix for annotation QC",
      "news/2020-09-08-jbrowse-data-submission" : "Submit your genome browser data",
      "news/2020-09-08-new-jbrowse-datasets" : "New genome browser datasets 2020-09-08",
      "news/2020-09-11-hermes-transposon-data-long" : "New dataset: Hermes transposon insertions",
      "news/2020-09-14-pombetalks-sept-16" : "PombeTalks September 16th",
      "news/2020-09-17-new-jbrowse-datasets" : "New genome browser datasets 2020-09-17",
      "news/2020-09-23-pombetalks-sept-30" : "PombeTalks September 30th",
      "news/2020-10-08-pombetalks-oct-14" : "PombeTalks October 14th",
      "news/2020-10-24-pombetalks-oct-28" : "PombeTalks October 28th",
      "news/2020-11-01-PAINT-annotations" : "GO annotations from PAINT",
      "news/2020-11-04-pombetalks-nov-11" : "PombeTalks November 11th",
      "news/2020-11-18-pombetalks-nov-25" : "PombeTalks November 25th",
      "news/2021-01-07-first-fission-yeast-micropublication-live" : "First S. pombe microPublication goes live",
      "news/2021-01-25-interpro-v83" : "PomBase now uses InterPro Version 83.0",
      "news/2021-02-04-id-mapper" : "PomBase identifier mapper now available",
      "news/2021-02-24-pombetalks-march-3" : "PombeTalks return March 3rd",
      "news/2021-03-10-pombetalks-march17" : "PombeTalks March 17th - new time!",
      "news/2021-03-27-pombetalks-march31" : "PombeTalks March 31st",
      "news/2021-03-30-new-protein-feature-graphic" : "New protein feature display",
      "news/2021-03-31-GC-content-jbrowse" : "New genome browser data: GC content",
      "news/2021-03-31-canto-qualitative-gene-expression" : "Qualitative gene expression annotation in Canto",
      "news/2021-04-08-pombetalks-april14" : "PombeTalks April 14th",
      "news/2021-04-15-interpro-v85" : "PomBase now uses InterPro Version 85.0",
      "news/2021-04-22-pombetalks-april-28" : "PombeTalks April 28th",
      "news/2021-04-29-diploid-genotypes-phenotypes" : "New diploid genotype & phenotype display",
      "news/2021-05-05-pombetalks-may-12" : "PombeTalks May 12th",
      "news/2021-05-20-pombetalks-may-26" : "PombeTalks May 26th",
      "news/2021-06-02-pombetalks-june-9" : "PombeTalks June 9th",
      "news/2021-06-15-gene-expression-violin-plots" : "Violin plots for gene expression",
      "news/2021-06-17-pombetalks-june-23rd" : "PombeTalks June 23rd",
      "news/2021-06-30-interpro-v86" : "PomBase now uses InterPro Version 86.0",
      "news/2021-06-30-pombetalks-july7th" : "PombeTalks July 7th",
      "news/2021-07-06-kegg-pathway-links" : "KEGG pathway links",
      "news/2021-07-14-pombetalks-july-21st" : "PombeTalks July 21st",
      "news/2021-07-28-alphafold-links" : "AlphaFold links",
      "news/2021-07-28-pombetalks-aug4th" : "PombeTalks August 4th",
      "news/2021-08-06-increased-disease-annotation-coverage" : "Improved gene-disease curation: over 1400 S. pombe genes",
      "news/2021-09-01-japonicusdb-release" : "JaponicusDB: a new fission yeast database",
      "news/2021-09-27-Genetics-database-pubs" : "PomBase & JaponicusDB preprints",
      "news/2021-10-18-new-protein-feature-searches" : "Coils, disorder, and more: new protein feature queries",
      "news/2021-11-23-interpro-v87" : "PomBase now uses InterPro Version 87.0",
      "news/2021-12-19-improve-go-filter" : "Improved options for filtering annotations by cell cycle phase",
      "news/2022-02-02-pombase-and-japonicusdb-papers" : "PomBase & JaponicusDB publications in the GENETICS MOD reports special issue",
      "news/2022-03-10-Atkinson-lncRNAs" : "An additional 5775 novel curated lncRNAs from Atkinson et al.",
      "news/2022-03-11-tor-nutritional-phosphoproteome-dataset" : "TOR and nutritional phosphoproteome dataset loaded",
      "news/2022-03-12-interprop-v88" : "PomBase now uses InterPro Version 88.0",
      "news/2022-03-23-new-human-orthologs" : "New human orthologs including 3 MRP complex subunits",
      "news/2022-03-26-1000-community-curated-publications" : "The fission yeast community have now curated over 1000 publications",
      "news/2022-04-04-genetics-publications" : "Making biological knowledge useful for humans and machines",
      "news/2022-04-13-phenotype-slim" : "New phenotype slim",
      "news/2022-05-05-pombetalks-may18-2022" : "PombeTalks May 18th",
      "news/2022-06-14-pombetalks-june15-2022" : "PombeTalks Wednesday June 15th",
      "news/2022-07-12-pombetalks-july-20th" : "Next pombeTalks: Wednesday, July 20th",
      "news/2022-08-13-pombetalks-aug-13" : "Next pombeTalks: Wednesday, August 17th",
      "news/2022-09-06-protein-sequence-changes" : "Protein sequence changes",
      "news/2022-09-15-pombetalks-sep-21" : "Next pombeTalks: Wednesday, September  21st",
      "news/2022-09-16-new-disease-associations" : "77 new disease gene associations",
      "news/2022-09-16-pombemine" : "PombeMine: an InterMine instance for S. pombe",
      "news/2022-10-13-new-to-pombase" : "New to fission yeast? check out our quick start guide for new users",
      "news/2022-10-13-pombetalks-oct-19th" : "Next pombeTalks: Wednesday, October 19th",
      "news/2022-10-14-Alpha-fold_RBSH" : "Using AlphaFold models to discover distant human and budding yeast homologues",
      "news/2022-11-11-pombetalks-16th-nov" : "Next pombeTalks: Wednesday, November 16th",
      "news/2022-11-29-new-gi-announcement" : "Genetic Interaction annotation model updated",
      "news/2022-12-07-rna-length-advanced-search" : "Querying by RNA length in the Advanced Search tool",
      "news/2022-12-08-pombetalks-14th-dec" : "Next PombeTalks: Wednesday, December 14th",
      "news/2022-12-15-gcbr-announce" : "Global Core Biodata Resource status for PomBase",
      "news/2023-01-24-new-utrs-from-tss" : "Revised canonical 5' UTRs",
      "news/2023-02-02-alphafold-on-gene-pages" : "AlphaFold protein structure on gene pages",
      "news/2023-02-22-pdb-structures-gene-pages" : "Experimental structures from PDB on gene pages",
      "news/2023-04-26-merging-nonsense-alleles" : "Curation update - \"nonsense mutation\" merged into \"partial amino acid deletion\"",
      "news/2023-06-19-rhea-reaction-diagrams" : "Reaction diagrams on GO molecular function term pages",
      "news/2023-07-27-protein-feature-viewer" : "Protein feature viewer added to gene pages",
      "news/2023-09-20-list-of-publication-genes" : "Accessing the list of genes from a paper",
      "news/2023-09-30-nomenclature-paper" : "Revised fission yeast nomenclature for genes and alleles",
      "news/2023-10-10-pombetalks-oct-18th" : "Next pombeTalks: Wednesday, October 18th",
      "news/2023-10-11-new-docs-and-video-site" : "New documentation and videos for Canto",
      "news/2023-11-06-pombetalks-nov-15th" : "Next pombeTalks: Wednesday, November 15th",
      "news/2023-11-12-interpro-release-97" : "PomBase updated to use InterPro Version 97.0",
      "news/2023-12-06-pombetalks-dec-13" : "Next pombeTalks: Wednesday, December 13th",
      "news/2023-12-14-ELIXIR-core-data-resource" : "PomBase joins ELIXIR Core Data Resources",
      "news/2024-01-17-pombetalks-jan-17" : "Next pombeTalks: Wednesday, January 17th",
      "news/2024-02-07-sent-substrates-to-advanced-search" : "Access target genes of individual gene products",
      "news/2024-02-08-modification-colours" : "Viewing protein modifications",
      "news/2024-02-15-pombetalks-feb-21" : "Next pombeTalks: Wednesday, February 21st",
      "news/2024-02-22-new-pombase-genetics-publication" : "New database update publication from PomBase",
      "news/2024-03-20-pombetalks-march-20" : "Next pombeTalks: March 20th",
      "news/2024-04-11-pombetalks-april-2024" : "News pombeTalks: Wednesday April 17th",
      "news/2024-05-04-interpro-release-99" : "PomBase updated to use InterPro Version 99.0",
      "news/2024-05-11-pombetalks-may-11" : "Next pombeTalks: Wednesday, May 15th",
      "news/2024-06-14-interpro-release-100" : "PomBase now includes data from InterPro v100.0",
      "news/2024-06-14-pombetalks-june-19th" : "Next pombeTalks: Wednesday, June 19th",
      "news/2024-06-21-disease-monarch" : "Human disease association changes - Monarch",
      "news/2024-07-03-rna-structures" : "RNA secondary structure predictions on gene pages",
      "news/2024-07-10-unknowns-coverage-update" : "\"Unknowns\" coverage update",
      "news/2024-08-13-new-human-and-cerevisiae-orthologs" : "New human and S. cerevisiae orthologs",
      "news/2024-08-29-pombetalks-session-5" : "pombeTalks Season 5 starting September 18th",
      "news/2024-09-03-interpro-release-101" : "PomBase updated to use InterPro Version 101.0",
      "news/2024-09-11-pombetalks-18th-sept" : "Next pombeTalks: September 18th",
      "news/2024-10-14-pombetalks-16th-oct" : "Next pombeTalks: Wednesday October 16th",
      "news/2024-11-12-protein-feature-view-changes" : "Changes to protein features view on gene pages",
      "news/2024-11-13-uniprot-protein-features" : "Additional protein features imported from UniProtKB",
      "news/2024-11-14-mobidb-lite-disorder" : "MobiDB disorder predictions",
      "news/2024-11-15-pombetalks-november" : "Next pombeTalks: Wednesday November 20th",
      "news/2024-11-22-new-pfam-paper" : "Discovery of new S. pombe orthologs of human and S. cerevisiae proteins using Pfam-N",
      "news/2024-12-04-pombetalks-december" : "Next pombeTalks: Wednesday December 11th",
      "news/index" : "News archive",
      "status" : "Genome Status",
      "status/centromeres" : "Centromeres",
      "status/gene-characterisation" : "Protein-coding gene characterisation status",
      "status/gene-characterisation-statistics-history" : "Protein-coding gene characterisation statistics history",
      "status/gene-coordinate-changes" : "Gene coordinate changes",
      "status/gene-coordinate-changes-RNA" : "RNA gene coordinate changes",
      "status/gene-coordinate-changes-protein-coding" : "Protein coding gene coordinate changes",
      "status/genome-overview" : "Genome overview",
      "status/index" : "Genome Status",
      "status/mating-type-region" : "Mating type region",
      "status/new-RNA-genes" : "New RNA genes since publication",
      "status/new-and-removed-genes" : "New and removed genes since publication",
      "status/new-protein-coding-genes" : "New protein coding genes since publication",
      "status/priority-unstudied-genes" : "Priority unstudied genes",
      "status/removed-RNA-genes" : "RNA genes removed since publication",
      "status/removed-protein-coding-genes" : "Protein coding genes removed since publication",
      "status/sequence-updates-pending" : "Pending sequence updates",
      "status/sequencing-status" : "Sequencing status",
      "status/sequencing-updates" : "S. pombe Sequence updates since July 2003",
      "status/statistics" : "Genome statistics",
      "status/telomeres" : "Telomeres",
      "status/unmapped_genes" : "Unmapped genes",
      "submit-data" : "PomBase Data Submission",
      "submit-data/bulk-annotation" : "Annotation dataset submission",
      "submit-data/gene-name-conflicts" : "Gene name conflicts",
      "submit-data/gene-names" : "Fission yeast gene name registry",
      "submit-data/gene-naming-committee-members" : "S. pombe Gene Naming Committee members",
      "submit-data/gene-naming-guidelines" : "Fission yeast gene naming guidelines",
      "submit-data/index" : "PomBase Data Submission"
   }
}
